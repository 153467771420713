<template>
  <div>Loading debug preview...</div>
</template>

<script>
import { Plugins } from "@capacitor/core";

import VueCookies from "vue-cookies";
import ApiService from "../common/service.api";

import CampaignService from "../common/mixins/Campaign";
import UserService from "../common/mixins/User";
import { mapActions, mapGetters } from "vuex";

import { dateToDayMonth, translate } from "../common/helper.auth";
import { IonicSafeString } from "@ionic/vue";
import moment from "moment";

export default {
  name: "Debug",
  mixins: [CampaignService, UserService],
  async created() {
    await this.logout();

    const { Storage } = Plugins;

    // this.$cookie.set("debug", 1);

    //set cookie "debug" to value 1, expires after 60seconds
    VueCookies.set("debug", 1, 0);

    console.log("**DEBUG** this.$route.params", this.$route.params);

    await ApiService.get(
      "Auth?username=w&password=w&tkf=" + this.$route.params.idUser
    ).then((res) => {
      Storage.set({
        key: "access_token",
        value: res.data.access_token,
      });
    });
    let idUser = this.$route.params.idUser;
    await this.getUserCampaignData(idUser);

    switch (this.$route.params.campaignModule) {
      case "details":
        this.$router.push("/campaign/mission/" + this.$route.params.idCampaign);
        break;
      case "mision":
        this.$router.push("/campaign/mission/" + this.$route.params.idCampaign);
        break;
      case "learning":
        this.$router.push(
          "/campaign/learning/" +
            this.$route.params.idCampaign +
            "/" +
            this.$route.params.titleCampaign
        );
        break;
      case "task":
        //   this.$router.push("/campaign/award/" + this.$route.params.idCampaign);
        console.log("disabled");
        break;
      case "socialshare":
        this.$router.push("/campaign/social/" + this.$route.params.idCampaign);
        break;
      case "kpi":
        this.$router.push("/campaign/kpi/" + this.$route.params.idCampaign);
        break;
      case "badge":
        this.$router.push("/campaign/badge/" + this.$route.params.idCampaign);
        break;
      case "award":
        this.$router.push("/campaign/award/" + this.$route.params.idCampaign);
        break;
      case "notification":
        this.$router.push("/campaign/mission/" + this.$route.params.idCampaign);
        break;
      default:
        console.log("end switch case");
    }
  },
  methods: {
    ...mapGetters("campaigns", {
      getOnBoardingId: "getOnBoardingId",
      getOnBoardingCampaign: "getOnBoardingCampaign",
    }),
    ...mapActions("auth", {
      logoutUser: "logoutUser",
    }),
    async logout() {
      await this.logoutUser();
    },
    async getUserCampaignData(idUser) {
      await ApiService.get(
        "CampaignManager/GetUserCampaingsDebug/" + idUser
      ).then((res) => {
        console.log("CampaignManager/GetUserCampaingsDebug/{isUser}", res);

        let mappedData = res.data.map((item) => {
          let x = {
            ...item,
            title: translate(item.title),
            description: translate(item.description),
          };
          x.startEnd = new IonicSafeString(
            this.$t("campaign.start_end", {
              from: dateToDayMonth(x.data_inizio),
              to: dateToDayMonth(x.data_fine),
            })
          ).value;
          x.totalNumberOfDays = moment(x.data_fine).diff(
            moment(x.data_inizio),
            "days"
          );
          x.remainingNumberOfDays = -moment().diff(moment(x.data_fine), "days");
          x.remainingDaysString = new IonicSafeString(
            this.$t("campaign.remaining_days", {
              value: x.remainingNumberOfDays,
            })
          ).value;
          //x.currentPercentage = Math.round(x.remainingNumberOfDays / x.totalNumberOfDays) * 100;
          x.currentPercentage = Math.round(x.percentualeCompletamento);
          x.currentPercentageVal = Math.min(
            1,
            (x.totalNumberOfDays - x.remainingNumberOfDays) /
              x.totalNumberOfDays
          );
          x.isEnded = moment() > moment(x.data_fine);
          x.isFuture = moment() < moment(x.data_inizio);
          x.isOpen = !x.isEnded && !x.isFuture;
          x.isClosed = x.isEnded || x.isFuture;
          if (!x.isOpen) {
            x.currentPercentage = 0;
          }

          // Add on boarding if available to Menu
          if (this.isOnBoarding(x)) {
            this.setOnBoardingIdToStorage(x.id);
          }

          return x;
        });
        this.saveCampaignsToStorage(mappedData);
      });
    },
  },
};
</script>
